/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi Share Program info (new)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramInfoNew { 
    /**
     * Program name
     */
    name: string;
    /**
     * Program short name
     */
    shortName: string;
    /**
     * Miradi object resource identifier
     */
    owningOrganizationIdentifier: string;
    /**
     * Flag indicating whether Program auto enrolls Organization members
     */
    autoEnrollOrganizationMembers: boolean;
    /**
     * Template name
     */
    templateName: string;
    /**
     * Template description
     */
    templateDescription?: string;
    /**
     * Program GIS workspace name
     */
    gisWorkspaceName: string;

    constructor(item?: Partial<ProgramInfoNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            shortName: {
                
                minLength: 1,
                maxLength: 50,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.shortName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 50);

                    return result;
                }
            },
            owningOrganizationIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.owningOrganizationIdentifier : itemOrValue;

                    if (itemOrValue?.owningOrganizationIdentifier != null) {
                        if (itemOrValue.owningOrganizationIdentifier === '') {
                            itemOrValue.owningOrganizationIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.owningOrganizationIdentifier = parseInt(itemOrValue.owningOrganizationIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.owningOrganizationIdentifier = parseFloat(itemOrValue.owningOrganizationIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            autoEnrollOrganizationMembers: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.autoEnrollOrganizationMembers : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            templateName: {
                
                minLength: 1,
                maxLength: 40,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.templateName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 40);

                    return result;
                }
            },
            templateDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.templateDescription : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            gisWorkspaceName: {
                
                minLength: 1,
                maxLength: 10,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.gisWorkspaceName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 10);

                    return result;
                }
            },
            isValid: ProgramInfoNew.isValid,
        };
    }

    static isValid(obj: ProgramInfoNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramInfoNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.shortName.isValid(item);
            result = result && validationObject.owningOrganizationIdentifier.isValid(item);
            result = result && validationObject.autoEnrollOrganizationMembers.isValid(item);
            result = result && validationObject.templateName.isValid(item);
            result = result && validationObject.templateDescription.isValid(item);
            result = result && validationObject.gisWorkspaceName.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

