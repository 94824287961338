/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Error codes returned in the event of validation errors
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ErrorCodeEnum = 'E00000' | 'E00001' | 'E00002' | 'E00003' | 'E00004' | 'E00005' | 'E00006' | 'E00007' | 'E00008' | 'E00009' | 'E00010' | 'E00011' | 'E00012' | 'E00013' | 'E00014' | 'E00015' | 'E00016' | 'E00017' | 'E00018' | 'E00019' | 'E00020' | 'E00021' | 'E00022' | 'E00023' | 'E00024' | 'E00025' | 'E00026' | 'E00027' | 'E00028' | 'E00029' | 'E00030' | 'E00031' | 'E00032' | 'E00033' | 'E00034' | 'E00035' | 'E00036' | 'E00037' | 'E00038' | 'E00039' | 'E00040' | 'E00041' | 'E00042' | 'E00043' | 'E00044' | 'E00045' | 'E00046' | 'E00047' | 'E00048' | 'E00049' | 'E00050' | 'E00051' | 'E00052' | 'E00053' | 'E00054' | 'E00055' | 'E00056' | 'E00057' | 'E00058' | 'E00059' | 'E00060' | 'E00061' | 'E00062' | 'E00063' | 'E00064' | 'E00065' | 'E00066' | 'E00067' | 'E00068' | 'E00069' | 'E00070' | 'E00071' | 'E00072' | 'E00073' | 'E00074' | 'E00075' | 'E00076' | 'E00077' | 'E00078' | 'E00079' | 'E00080' | 'E00081' | 'E00082' | 'E00083' | 'E00084' | 'E00085' | 'E00086' | 'E00087' | 'E00088' | 'E00089' | 'E00090' | 'E00091' | 'E00092' | 'E00093' | 'E00094' | 'E00095' | 'E00096' | 'E00097' | 'E00098' | 'E00099' | 'E00100' | 'E00101' | 'E00102' | 'E00103' | 'E00104' | 'E00105' | 'E00106' | 'E00107' | 'E00108' | 'E00109' | 'E00110' | 'E00111' | 'E00112' | 'E00113' | 'E00114' | 'E00115' | 'E00116' | 'E00117' | 'E00118' | 'E00119' | 'E00120' | 'E00121' | 'E00122' | 'E00123' | 'E00124' | 'E00125' | 'E00126' | 'E00127' | 'E00128' | 'E00129' | 'E00130' | 'E00131' | 'E00132' | 'E00133' | 'E00134' | 'E00135' | 'E00136' | 'E00137' | 'E00138' | 'E00139' | 'E00140' | 'E00141' | 'E00142' | 'E00143' | 'E00144' | 'E00145' | 'E00146' | 'E00147' | 'E00148' | 'E00149' | 'E00150' | 'E00151' | 'E00152' | 'E00153' | 'E00154' | 'E00155' | 'E00156' | 'E00157' | 'E00158' | 'E00159' | 'E00160' | 'E00161' | 'E00162' | 'E00163' | 'E00164' | 'E00165' | 'E00166';

export const ErrorCodeEnum = {
    E00000: 'E00000' as ErrorCodeEnum,
    E00001: 'E00001' as ErrorCodeEnum,
    E00002: 'E00002' as ErrorCodeEnum,
    E00003: 'E00003' as ErrorCodeEnum,
    E00004: 'E00004' as ErrorCodeEnum,
    E00005: 'E00005' as ErrorCodeEnum,
    E00006: 'E00006' as ErrorCodeEnum,
    E00007: 'E00007' as ErrorCodeEnum,
    E00008: 'E00008' as ErrorCodeEnum,
    E00009: 'E00009' as ErrorCodeEnum,
    E00010: 'E00010' as ErrorCodeEnum,
    E00011: 'E00011' as ErrorCodeEnum,
    E00012: 'E00012' as ErrorCodeEnum,
    E00013: 'E00013' as ErrorCodeEnum,
    E00014: 'E00014' as ErrorCodeEnum,
    E00015: 'E00015' as ErrorCodeEnum,
    E00016: 'E00016' as ErrorCodeEnum,
    E00017: 'E00017' as ErrorCodeEnum,
    E00018: 'E00018' as ErrorCodeEnum,
    E00019: 'E00019' as ErrorCodeEnum,
    E00020: 'E00020' as ErrorCodeEnum,
    E00021: 'E00021' as ErrorCodeEnum,
    E00022: 'E00022' as ErrorCodeEnum,
    E00023: 'E00023' as ErrorCodeEnum,
    E00024: 'E00024' as ErrorCodeEnum,
    E00025: 'E00025' as ErrorCodeEnum,
    E00026: 'E00026' as ErrorCodeEnum,
    E00027: 'E00027' as ErrorCodeEnum,
    E00028: 'E00028' as ErrorCodeEnum,
    E00029: 'E00029' as ErrorCodeEnum,
    E00030: 'E00030' as ErrorCodeEnum,
    E00031: 'E00031' as ErrorCodeEnum,
    E00032: 'E00032' as ErrorCodeEnum,
    E00033: 'E00033' as ErrorCodeEnum,
    E00034: 'E00034' as ErrorCodeEnum,
    E00035: 'E00035' as ErrorCodeEnum,
    E00036: 'E00036' as ErrorCodeEnum,
    E00037: 'E00037' as ErrorCodeEnum,
    E00038: 'E00038' as ErrorCodeEnum,
    E00039: 'E00039' as ErrorCodeEnum,
    E00040: 'E00040' as ErrorCodeEnum,
    E00041: 'E00041' as ErrorCodeEnum,
    E00042: 'E00042' as ErrorCodeEnum,
    E00043: 'E00043' as ErrorCodeEnum,
    E00044: 'E00044' as ErrorCodeEnum,
    E00045: 'E00045' as ErrorCodeEnum,
    E00046: 'E00046' as ErrorCodeEnum,
    E00047: 'E00047' as ErrorCodeEnum,
    E00048: 'E00048' as ErrorCodeEnum,
    E00049: 'E00049' as ErrorCodeEnum,
    E00050: 'E00050' as ErrorCodeEnum,
    E00051: 'E00051' as ErrorCodeEnum,
    E00052: 'E00052' as ErrorCodeEnum,
    E00053: 'E00053' as ErrorCodeEnum,
    E00054: 'E00054' as ErrorCodeEnum,
    E00055: 'E00055' as ErrorCodeEnum,
    E00056: 'E00056' as ErrorCodeEnum,
    E00057: 'E00057' as ErrorCodeEnum,
    E00058: 'E00058' as ErrorCodeEnum,
    E00059: 'E00059' as ErrorCodeEnum,
    E00060: 'E00060' as ErrorCodeEnum,
    E00061: 'E00061' as ErrorCodeEnum,
    E00062: 'E00062' as ErrorCodeEnum,
    E00063: 'E00063' as ErrorCodeEnum,
    E00064: 'E00064' as ErrorCodeEnum,
    E00065: 'E00065' as ErrorCodeEnum,
    E00066: 'E00066' as ErrorCodeEnum,
    E00067: 'E00067' as ErrorCodeEnum,
    E00068: 'E00068' as ErrorCodeEnum,
    E00069: 'E00069' as ErrorCodeEnum,
    E00070: 'E00070' as ErrorCodeEnum,
    E00071: 'E00071' as ErrorCodeEnum,
    E00072: 'E00072' as ErrorCodeEnum,
    E00073: 'E00073' as ErrorCodeEnum,
    E00074: 'E00074' as ErrorCodeEnum,
    E00075: 'E00075' as ErrorCodeEnum,
    E00076: 'E00076' as ErrorCodeEnum,
    E00077: 'E00077' as ErrorCodeEnum,
    E00078: 'E00078' as ErrorCodeEnum,
    E00079: 'E00079' as ErrorCodeEnum,
    E00080: 'E00080' as ErrorCodeEnum,
    E00081: 'E00081' as ErrorCodeEnum,
    E00082: 'E00082' as ErrorCodeEnum,
    E00083: 'E00083' as ErrorCodeEnum,
    E00084: 'E00084' as ErrorCodeEnum,
    E00085: 'E00085' as ErrorCodeEnum,
    E00086: 'E00086' as ErrorCodeEnum,
    E00087: 'E00087' as ErrorCodeEnum,
    E00088: 'E00088' as ErrorCodeEnum,
    E00089: 'E00089' as ErrorCodeEnum,
    E00090: 'E00090' as ErrorCodeEnum,
    E00091: 'E00091' as ErrorCodeEnum,
    E00092: 'E00092' as ErrorCodeEnum,
    E00093: 'E00093' as ErrorCodeEnum,
    E00094: 'E00094' as ErrorCodeEnum,
    E00095: 'E00095' as ErrorCodeEnum,
    E00096: 'E00096' as ErrorCodeEnum,
    E00097: 'E00097' as ErrorCodeEnum,
    E00098: 'E00098' as ErrorCodeEnum,
    E00099: 'E00099' as ErrorCodeEnum,
    E00100: 'E00100' as ErrorCodeEnum,
    E00101: 'E00101' as ErrorCodeEnum,
    E00102: 'E00102' as ErrorCodeEnum,
    E00103: 'E00103' as ErrorCodeEnum,
    E00104: 'E00104' as ErrorCodeEnum,
    E00105: 'E00105' as ErrorCodeEnum,
    E00106: 'E00106' as ErrorCodeEnum,
    E00107: 'E00107' as ErrorCodeEnum,
    E00108: 'E00108' as ErrorCodeEnum,
    E00109: 'E00109' as ErrorCodeEnum,
    E00110: 'E00110' as ErrorCodeEnum,
    E00111: 'E00111' as ErrorCodeEnum,
    E00112: 'E00112' as ErrorCodeEnum,
    E00113: 'E00113' as ErrorCodeEnum,
    E00114: 'E00114' as ErrorCodeEnum,
    E00115: 'E00115' as ErrorCodeEnum,
    E00116: 'E00116' as ErrorCodeEnum,
    E00117: 'E00117' as ErrorCodeEnum,
    E00118: 'E00118' as ErrorCodeEnum,
    E00119: 'E00119' as ErrorCodeEnum,
    E00120: 'E00120' as ErrorCodeEnum,
    E00121: 'E00121' as ErrorCodeEnum,
    E00122: 'E00122' as ErrorCodeEnum,
    E00123: 'E00123' as ErrorCodeEnum,
    E00124: 'E00124' as ErrorCodeEnum,
    E00125: 'E00125' as ErrorCodeEnum,
    E00126: 'E00126' as ErrorCodeEnum,
    E00127: 'E00127' as ErrorCodeEnum,
    E00128: 'E00128' as ErrorCodeEnum,
    E00129: 'E00129' as ErrorCodeEnum,
    E00130: 'E00130' as ErrorCodeEnum,
    E00131: 'E00131' as ErrorCodeEnum,
    E00132: 'E00132' as ErrorCodeEnum,
    E00133: 'E00133' as ErrorCodeEnum,
    E00134: 'E00134' as ErrorCodeEnum,
    E00135: 'E00135' as ErrorCodeEnum,
    E00136: 'E00136' as ErrorCodeEnum,
    E00137: 'E00137' as ErrorCodeEnum,
    E00138: 'E00138' as ErrorCodeEnum,
    E00139: 'E00139' as ErrorCodeEnum,
    E00140: 'E00140' as ErrorCodeEnum,
    E00141: 'E00141' as ErrorCodeEnum,
    E00142: 'E00142' as ErrorCodeEnum,
    E00143: 'E00143' as ErrorCodeEnum,
    E00144: 'E00144' as ErrorCodeEnum,
    E00145: 'E00145' as ErrorCodeEnum,
    E00146: 'E00146' as ErrorCodeEnum,
    E00147: 'E00147' as ErrorCodeEnum,
    E00148: 'E00148' as ErrorCodeEnum,
    E00149: 'E00149' as ErrorCodeEnum,
    E00150: 'E00150' as ErrorCodeEnum,
    E00151: 'E00151' as ErrorCodeEnum,
    E00152: 'E00152' as ErrorCodeEnum,
    E00153: 'E00153' as ErrorCodeEnum,
    E00154: 'E00154' as ErrorCodeEnum,
    E00155: 'E00155' as ErrorCodeEnum,
    E00156: 'E00156' as ErrorCodeEnum,
    E00157: 'E00157' as ErrorCodeEnum,
    E00158: 'E00158' as ErrorCodeEnum,
    E00159: 'E00159' as ErrorCodeEnum,
    E00160: 'E00160' as ErrorCodeEnum,
    E00161: 'E00161' as ErrorCodeEnum,
    E00162: 'E00162' as ErrorCodeEnum,
    E00163: 'E00163' as ErrorCodeEnum,
    E00164: 'E00164' as ErrorCodeEnum,
    E00165: 'E00165' as ErrorCodeEnum,
    E00166: 'E00166' as ErrorCodeEnum
};

marker('E00000');
marker('E00001');
marker('E00002');
marker('E00003');
marker('E00004');
marker('E00005');
marker('E00006');
marker('E00007');
marker('E00008');
marker('E00009');
marker('E00010');
marker('E00011');
marker('E00012');
marker('E00013');
marker('E00014');
marker('E00015');
marker('E00016');
marker('E00017');
marker('E00018');
marker('E00019');
marker('E00020');
marker('E00021');
marker('E00022');
marker('E00023');
marker('E00024');
marker('E00025');
marker('E00026');
marker('E00027');
marker('E00028');
marker('E00029');
marker('E00030');
marker('E00031');
marker('E00032');
marker('E00033');
marker('E00034');
marker('E00035');
marker('E00036');
marker('E00037');
marker('E00038');
marker('E00039');
marker('E00040');
marker('E00041');
marker('E00042');
marker('E00043');
marker('E00044');
marker('E00045');
marker('E00046');
marker('E00047');
marker('E00048');
marker('E00049');
marker('E00050');
marker('E00051');
marker('E00052');
marker('E00053');
marker('E00054');
marker('E00055');
marker('E00056');
marker('E00057');
marker('E00058');
marker('E00059');
marker('E00060');
marker('E00061');
marker('E00062');
marker('E00063');
marker('E00064');
marker('E00065');
marker('E00066');
marker('E00067');
marker('E00068');
marker('E00069');
marker('E00070');
marker('E00071');
marker('E00072');
marker('E00073');
marker('E00074');
marker('E00075');
marker('E00076');
marker('E00077');
marker('E00078');
marker('E00079');
marker('E00080');
marker('E00081');
marker('E00082');
marker('E00083');
marker('E00084');
marker('E00085');
marker('E00086');
marker('E00087');
marker('E00088');
marker('E00089');
marker('E00090');
marker('E00091');
marker('E00092');
marker('E00093');
marker('E00094');
marker('E00095');
marker('E00096');
marker('E00097');
marker('E00098');
marker('E00099');
marker('E00100');
marker('E00101');
marker('E00102');
marker('E00103');
marker('E00104');
marker('E00105');
marker('E00106');
marker('E00107');
marker('E00108');
marker('E00109');
marker('E00110');
marker('E00111');
marker('E00112');
marker('E00113');
marker('E00114');
marker('E00115');
marker('E00116');
marker('E00117');
marker('E00118');
marker('E00119');
marker('E00120');
marker('E00121');
marker('E00122');
marker('E00123');
marker('E00124');
marker('E00125');
marker('E00126');
marker('E00127');
marker('E00128');
marker('E00129');
marker('E00130');
marker('E00131');
marker('E00132');
marker('E00133');
marker('E00134');
marker('E00135');
marker('E00136');
marker('E00137');
marker('E00138');
marker('E00139');
marker('E00140');
marker('E00141');
marker('E00142');
marker('E00143');
marker('E00144');
marker('E00145');
marker('E00146');
marker('E00147');
marker('E00148');
marker('E00149');
marker('E00150');
marker('E00151');
marker('E00152');
marker('E00153');
marker('E00154');
marker('E00155');
marker('E00156');
marker('E00157');
marker('E00158');
marker('E00159');
marker('E00160');
marker('E00161');
marker('E00162');
marker('E00163');
marker('E00164');
marker('E00165');
marker('E00166');

