
export class MarketingPreferences {
  interests?: any[];
  subscriberStatus?: 'subscribed' | 'unsubscribed' | 'cleaned' | 'pending' | 'transactional' | 'archived';
}


export class UserPreferences {

  dismissedUxElements?: string[]; // 'new-feature-assumption', 'new-feature-output', 'new-feature-support'
  marketingPreferences?: MarketingPreferences;

  constructor(item?: Partial<UserPreferences>) {
    Object.assign(this, item);

    this.dismissedUxElements = this.dismissedUxElements || [];
    this.marketingPreferences = this.marketingPreferences || {};
  }

}
